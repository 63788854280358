import React from "react";
import contact from "../../assets/contact-us.png";
import { useNavigate } from "react-router-dom";
import "./contactComponent.css";

const ContactComponent = () => {
  const navigate = useNavigate();
  const whatsappNumber = "+6282154746793";
  const whatsappLink = `https://wa.me/${whatsappNumber}`;
  return (
    <>
      <div className="contact">
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <img src={contact} alt="Contact Us" />
        </a>
      </div>
    </>
  );
};

export default ContactComponent;
