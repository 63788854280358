import React, { useContext, useState, useEffect } from "react";
// import "./eventSummary.css";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import AuthService from "../../services/authService";
import format from "date-fns/format";
import { AppContext } from "../../App";
import { BeatLoader } from "react-spinners";
import { handleBookEvent } from "../../services/eventService";

const EventSummary = () => {
  const { name, email, setName, setEmail, phone, setPhone, setBookingNumber } =
    useContext(AppContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const jumlahTiket = useSelector((state) => state.ticket.jumlahTiket);
  const totalCostEvent = useSelector((state) => state.event.totalCostEvent);
  const selectedClassEvent = useSelector(
    (state) => state.event.selectedClassEvent
  );
  const token = localStorage.getItem("user");
  const { slug } = useParams();

  const data = {
    qty: jumlahTiket,
    type: "event",
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (totalCostEvent === 0) {
          navigate(`/event/${slug}`);
        }
        const response = await AuthService.getProfile();
        setName(response.data.user.name);
        setEmail(response.data.user.email);
        setPhone(response.data.user.phone);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setError("Failed to fetch profile");
        }
      }
    };
    fetchProfile();
  }, []);

  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
      await handleBookEvent(
        { name, email, phone, ...data },
        token,
        slug,
        selectedClassEvent,
        navigate,
        setBookingNumber
      );
    } catch (error) {
      setError("An error occurred"); // Handle errors appropriately
    } finally {
      setIsLoading(false); // Ensure loading is set to false in the end
    }
  };

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="bg-ticket">
        <h1>Booker Information</h1>
        <div className="bg-booking">
          <div className="bg-white">
            <h5>Summary</h5>
            <div className="summary-bg">
              <span>Jumlah Tiket: {jumlahTiket}</span>
              <span>Category/Class: {selectedClassEvent}</span>
              <span>Harga Total: Rp {totalCostEvent},-</span>
            </div>
            <div className="form">
              <div className="form-group">
                <input
                  placeholder="Name"
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  placeholder="Email"
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  placeholder="Phone"
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <button
              disabled={isLoading} // Disable button when loading
              className="btn btn-book"
              onClick={handleButtonClick}
            >
              {isLoading ? <BeatLoader size={8} color="#FFF" /> : "Lanjutkan"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventSummary;
